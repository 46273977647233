body {
  margin: 0;
  background-color: #eee;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  max-height: 70px;
  width: auto;
  padding-bottom: 5px;
}

.inline-icon {
  vertical-align: bottom;
}

.without-border-collection {
  border: none !important;
}

.page-footer {
  padding-top: 0;
}
.page-footer .footer-copyright {
  color: black;
}

@media only screen and (max-width: 320px) {
  .carousel {
    height: 75px;
  }
}

@media only screen and (min-width: 375px) {
  .carousel {
    height: 125px;
  }
}

@media only screen and (min-width: 600px) {
  .carousel {
    height: 150px;
  }
}

@media only screen and (min-width: 600px) {
  .carousel {
    height: 200px;
  }
}

@media only screen and (min-width: 700px) {
  .carousel {
    height: 250px;
  }
}

@media only screen and (min-width: 992px) {
  .carousel {
    height: 300px;
  }
}

@media only screen and (min-width: 1200px) {
  .carousel {
    height: 400px;
  }
}
